var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-page" },
    [
      _c(
        "hero",
        { attrs: { color: "clear", nav: !_vm.$route.meta.hideNavbar } },
        [
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-image" }, [
                _c(
                  "div",
                  { staticClass: "card-content is-flex bim-meet-logo" },
                  [
                    _c("img", {
                      staticClass: "logo",
                      attrs: {
                        src: require("@/assets/images/bim-meet-icon.png")
                      }
                    }),
                    _c(
                      "span",
                      { staticClass: "has-text-info has-text-weight-medium" },
                      [_vm._v("BIM")]
                    ),
                    _c(
                      "span",
                      { staticClass: "has-text-primary has-text-weight-bold" },
                      [_vm._v("Meet")]
                    )
                  ]
                )
              ]),
              _c("div", { staticClass: "card-content" }, [
                _c("div", { staticClass: "content" }, [_c("login")], 1)
              ])
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }