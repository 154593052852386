import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import Login from '@/components/auth/Login.vue';
import Hero from '@/components/bulma/Hero.vue';
var LoginPage = (function (_super) {
    __extends(LoginPage, _super);
    function LoginPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LoginPage = __decorate([
        Component({
            components: { Hero: Hero, Login: Login }
        })
    ], LoginPage);
    return LoginPage;
}(Vue));
export default LoginPage;
